// src/services/userInfoService.tsx
import { AxiosService } from '../axios/axios';

export interface UserInfoResponse {
    id: string;
    username: string | null;
    email: string;
    first_name: string;
    last_name: string;
    mobile_number: string | null;
    timezone: string;
    last_used_account_id: string | null;
}

export const userinfo = async (): Promise<UserInfoResponse> => {
    try {
        const response = await AxiosService.get<UserInfoResponse>('/users/userinfo');
        return response.data;
    } catch (error) {
        console.error('Error fetching user info:', error);
        throw error;
    }
};
