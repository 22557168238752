// src/services/accountService.ts

import { AxiosService } from "../axios/axios";

export interface Account {
  account_id: string;
  account_name: string;
  is_manual_entry: number;
  timezone?: string;
  trading_currency: string | null;
  starting_balance?: number | 0.0;
  local_currency?: string | null;
  profit_calculation_method?: string;
  integration_partner_id: string | null;
  integration_status: string;
  last_sync_at: string | null;
  created_at: string;
  updated_at: string;
}

export interface AccountsResponse {
  accounts: Account[];
  last_used_account_id: string | null;
}

export const fetchAccounts = async (): Promise<AccountsResponse> => {
  try {
    const response = await AxiosService.get<AccountsResponse>(
      "/accounts/listAccounts"
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching accounts:", error);
    throw error;
  }
};

export const getAccountById = async (accountId: string): Promise<Account> => {
  try {
    const response = await AxiosService.get(`/accounts/${accountId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching account ${accountId}:`, error);
    throw error;
  }
};

export const createAccount = async (
  accountData: Partial<Account>
): Promise<Account> => {
  try {
    const response = await AxiosService.post(
      "/accounts/createAccount",
      accountData
    );
    return response.data;
  } catch (error) {
    console.error("Error creating account:", error);
    throw error;
  }
};

export const updateAccount = async (
  accountId: string,
  accountData: Partial<Account>
): Promise<Account> => {
  try {
    const response = await AxiosService.put(
      `/accounts/${accountId}`,
      accountData
    );
    return response.data;
  } catch (error) {
    console.error(`Error updating account ${accountId}:`, error);
    throw error;
  }
};

export const deleteAccount = async (accountId: string): Promise<void> => {
  try {
    await AxiosService.delete(`/accounts/${accountId}`);
  } catch (error) {
    console.error(`Error deleting account ${accountId}:`, error);
    throw error;
  }
};
